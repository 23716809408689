
let env = process.env.REACT_APP_STAGE;
if (window.location.hostname === 'localhost') {
	env = 'local';
}

const s3 = {
	local: {
		REGION: `eu-west-2`,
		BUCKET: `nobound-uploads-develop`
	},
	develop: {
		REGION: `eu-west-2`,
		BUCKET: `nobound-uploads-develop`
	},
};

const cognito = {
	local: {
		REGION: `eu-west-2`,
		USER_POOL_ID: `eu-west-2_rF0o53ZaJ`,
		APP_CLIENT_ID: `160g8l6cf50ruv7hovv9a7l87l`,
		// IDENTITY_POOL_ID: `eu-west-2:1bf63c45-aec6-41b1-8484-d64417763083`,
		IDENTITY_POOL_ID: `eu-west-2:46d2328c-9b3f-4eda-9035-abb55dffc5a5`,
		USERPOOL_DOMAIN: `https://nobound-app-client-2019-07-26.auth.eu-west-2.amazoncognito.com`,
	},
	develop: {
		REGION: `eu-west-2`,
		USER_POOL_ID: `eu-west-2_rF0o53ZaJ`,
		APP_CLIENT_ID: `160g8l6cf50ruv7hovv9a7l87l`,
		// IDENTITY_POOL_ID: `eu-west-2:1bf63c45-aec6-41b1-8484-d64417763083`,
		IDENTITY_POOL_ID: `eu-west-2:46d2328c-9b3f-4eda-9035-abb55dffc5a5`,
		USERPOOL_DOMAIN: `https://nobound-app-client-2019-07-26.auth.eu-west-2.amazoncognito.com`,
	},
};

const apiGateway = {
	local: {
		URL: `http://localhost:4001/${env}`,
		REGION: `eu-west-2`,
	},
	develop: {
		URL: `https://3sl6dctkwh.execute-api.eu-west-2.amazonaws.com/dev`,
		REGION: `eu-west-2`,
	}
};

const config = {
	s3: s3[env],
	cognito: cognito[env],
	apiGateway: apiGateway[env]
};

export default config;